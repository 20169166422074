import '../styles/Services.css'

const Services = () => {
    return (
        <body>

            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"></link>

            <div id="generic_price_table">
                <div className="breadcrumbs breadcrums-div" data-aos="fade-in">
                    <div className="container">
                        <h2>Nuestros planes</h2>
                        <p>Conoce un poco más sobre nuestros servicios, estaremos contigo en los momentos más difíciles.</p>
                    </div>
                </div>

                <section>


                    <div className="container">


                        <div className="row">
                            <div className="col-md-4">


                                <div className="generic_content clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Básico</span>
                                            </div>


                                        </div>



                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">$</span>
                                                <span className="currency">21.000</span>
                                                <span className="cent">.00</span>
                                                <span className="month">/MES</span>
                                            </span>
                                        </div>


                                    </div>



                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Salas de velación red GAVIRIA adicional más de 2000 salas de velación en convenios a nivel nacional</li>
                                            <li><i className="bi bi-check2-circle"> </i>Auxilio por no prestación del servicio dependiendo el plan hasta 4 S.M.M.L.V</li>
                                            <li><i className="bi bi-check2-circle"> </i>Traslado nacional del cuerpo sin límite de kilometraje</li>
                                            <li><i className="bi bi-check2-circle"> </i>Transporte para acompañantes (donde se requiera) bus o buseta</li>
                                            <li><i className="bi bi-check2-circle"> </i>Bóveda o cremación (cementerio Distrital, Local o Municipal)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Urna para cenizas (para los servicios de cremación)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Tramites de rigor</li>
                                            <li><i className="bi bi-check2-circle"> </i>Preservación de cuerpo</li>
                                            <li><i className="bi bi-check2-circle"> </i>Cofre tipo plan</li>
                                            <li><i className="bi bi-check2-circle"> </i>Servicio de cafetería</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ofrenda floral</li>
                                            <li><i className="bi bi-check2-circle"> </i>Carteles</li>
                                            <li><i className="bi bi-check2-circle"> </i>Coche fúnebre</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ceremonia religiosa</li>
                                            <li><i className="bi bi-check2-circle"> </i>Pergamino recordatorio</li>
                                        </ul>
                                    </div>



                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>


                                </div>


                            </div>

                            <div className="col-md-4">

                                <div className="generic_content active clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Superior</span>
                                            </div>


                                        </div>

                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">$</span>
                                                <span className="currency">36.000</span>
                                                <span className="cent">.00</span>
                                                <span className="month">/MES</span>
                                            </span>
                                        </div>


                                    </div>



                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Salas de velación red GAVIRIA adicional más de 2000 salas de velación en convenios a nivel nacional</li>
                                            <li><i className="bi bi-check2-circle"> </i>Auxilio por no prestación del servicio dependiendo el plan hasta 8.5 S.M.M.L.V</li>
                                            <li><i className="bi bi-check2-circle"> </i>Traslado nacional del cuerpo sin límite de kilometraje</li>
                                            <li><i className="bi bi-check2-circle"> </i>Transporte para acompañantes (donde se requiera)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Bóveda, lote o cremación en parque cementerio</li>
                                            <li><i className="bi bi-check2-circle"> </i>Tramites de rigor</li>
                                            <li><i className="bi bi-check2-circle"> </i>Preservación de cuerpo</li>
                                            <li><i className="bi bi-check2-circle"> </i>Cofre gama media</li>
                                            <li><i className="bi bi-check2-circle"> </i>Servicio de cafetería</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ofrenda floral</li>
                                            <li><i className="bi bi-check2-circle"> </i>Carteles</li>
                                            <li><i className="bi bi-check2-circle"> </i>Coche fúnebre</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ceremonia religiosa</li>
                                            <li><i className="bi bi-check2-circle"> </i>Osario o cenízaro</li>
                                            <li><i className="bi bi-check2-circle"> </i>Pergamino recordatorio</li>

                                        </ul>
                                    </div>



                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>


                                </div>


                            </div>
                            <div className="col-md-4">


                                <div className="generic_content clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Élite</span>
                                            </div>


                                        </div>

                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">$</span>
                                                <span className="currency">49.000</span>
                                                <span className="cent">.00</span>
                                                <span className="month">/MES</span>
                                            </span>
                                        </div>


                                    </div>

                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Salas de velación red GAVIRIA adicional más de 2000 salas de velación en convenios a nivel nacional</li>
                                            <li><i className="bi bi-check2-circle"> </i>Auxilio por no prestación del servicio dependiendo el plan hasta 12 S.M.M.L.V</li>
                                            <li><i className="bi bi-check2-circle"> </i>Traslado nacional del cuerpo sin límite de kilometraje</li>
                                            <li><i className="bi bi-check2-circle"> </i>Transporte para acompañantes (donde se requiera)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Bóveda, lote o cremación en parque cementerio</li>
                                            <li><i className="bi bi-check2-circle"> </i>Tramites de rigor</li>
                                            <li><i className="bi bi-check2-circle"> </i>Preservación de cuerpo</li>
                                            <li><i className="bi bi-check2-circle"> </i>Cofre gama alta</li>
                                            <li><i className="bi bi-check2-circle"> </i>Servicio de cafetería</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ofrenda floral</li>
                                            <li><i className="bi bi-check2-circle"> </i>Carteles</li>
                                            <li><i className="bi bi-check2-circle"> </i>Suvenir</li>
                                            <li><i className="bi bi-check2-circle"> </i>Aviso en prensa</li>
                                            <li><i className="bi bi-check2-circle"> </i>Coche fúnebre</li>
                                            <li><i className="bi bi-check2-circle"> </i>Osario o cenízaro</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ceremonia religiosa</li>
                                            <li><i className="bi bi-check2-circle"> </i>Pergamino recordatorio</li>
                                        </ul>
                                    </div>

                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </body>
    )
}

export default Services