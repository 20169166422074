import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import { useEffect, useState } from 'react';

const HeaderNav = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <header id='headernav' className={` ${isScrolled ? "headernav__noscroll fixed-top" : "headernav__scroll fixed-top"}`} >
            <div className='d-flex justify-content-around headernav__container '>

                {/*Logo*/}
                <NavLink to="/" className={`col-4 ${isScrolled ? 'headernav__logoscroll' : 'headernav__logo'}`}>
                    <div className='d-flex flex-column align-items-center'>
                        <img src={isScrolled ? "./img/Logo-funeral-blanco-bg.png" : "./img/Logo-funeral-blanco-bg.png"} alt='FuneralHome-Logo' />
                        <div className='d-flex flex-column align-items-center'>
                            <span className={`${isScrolled ? 'headernav__text__logo1scroll' : 'headernav__text__logo1'}`}>FUNERAL HOME</span>
                            <span className={`${isScrolled ? 'headernav__text__logo2scroll' : 'headernav__text__logo2'}`}>TU APOYO CUANDO MAS LO NECESITAS</span>
                        </div>
                    </div>
                </NavLink>

                {/*links*/}
                <div className='headernav__links col-7 d-flex align-items-center'>
                    <div className='container-xxl headernav__links_link'>

                        <div className={`d-flex justify-content-evenly ${isScrolled ? 'headernav__linkscroll' : 'headernav__link'}`} >
                            <NavLink to="/">Inicio</NavLink>
                            <NavLink to="./services">Servicios funerarios</NavLink>
                            <NavLink to="./abautUs">Nosotros</NavLink>
                            <NavLink to="./ContactPage">Contáctanos</NavLink>
                        </div>

                    </div>

                    <div className=' d-flex justify-content-around headernav__text__container'>

                        <NavLink to="./services" className='headernav__text__af'>Afiliaciones</NavLink>


                        <div onClick={handleMenuClick} className={`${isScrolled ? 'menu-hamburguesa-2' : 'menu-hamburguesa'}`}>
                            <div className="menu-hamburguesa-container">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <div className={` ${isScrolled ? 'headernav__dropdown__scroll' : 'headernav__dropdown'}`}>
                    <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
                        Inicio
                    </NavLink>
                    <NavLink to="./services" onClick={() => setIsMenuOpen(false)}>
                        Servicios funerarios
                    </NavLink>
                    <NavLink to="./abautUs" onClick={() => setIsMenuOpen(false)}>
                        Nosotros
                    </NavLink>
                    <NavLink to="./ContactPage" onClick={() => setIsMenuOpen(false)}>
                        Contáctanos
                    </NavLink>
                </div>
            )}
        </header >
    );
};

export default HeaderNav;