import Marquee from "react-fast-marquee";
import '../styles/HomePageSlideServices.css';

function HomeSlideServices() {

    const images = [
        { src: './img/HomeSliderServices/church.svg', tittle: 'Ceremonia de exequias', color: 'green' },
        { src: './img/HomeSliderServices/bx-book-open.svg', tittle: 'Trámites legales', color: 'green' },
        { src: './img/HomeSliderServices/lawyer-svgrepo-com.svg', tittle: 'Tanatopraxia', color: 'green' },
        { src: './img/HomeSliderServices/mortuary.svg', tittle: 'Salas de velación', color: 'green' },
        { src: './img/HomeSliderServices/pope-priest-svgrepo-com.svg', tittle: 'Cofre funebre', color: 'green' },
        { src: './img/HomeSliderServices/bunch-of-flowers-svgrepo-com.svg', tittle: 'Arreglo floral', color: 'green' },
        { src: './img/HomeSliderServices/bus.svg', tittle: 'Vehiculo hasta 20 personas', color: 'green' },
        { src: './img/HomeSliderServices/bx-bible.svg', tittle: 'Libro de novedades, firma y denario', color: 'green' },
        { src: './img/HomeSliderServices/hearse-svgrepo-com.svg', tittle: 'Traslado de cuerpo', color: 'green' },
        { src: './img/HomeSliderServices/post-stamp-svgrepo-com.svg', tittle: 'Serie de carteles', color: 'green' },
        { src: './img/HomeSliderServices/ribbon-mark-2-svgrepo-com.svg', tittle: 'Cinta impresa', color: 'green' },
    ];


    return (
        <div className='py-4'>
            <div className='container-xxl'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card__wripper'>
                            <Marquee >
                                <div className='d-flex card__wripper_maq'>
                                    {images.map((image, index) => (

                                        <div key={image.src} className={`${image.color} marquee__div d-flex align-items-center flex-column justify-content-center`}>
                                            <img src={image.src} alt='Funeral Home Servicios'></img>
                                            <span>{image.tittle}</span>
                                        </div>

                                    ))}
                                </div>
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSlideServices
