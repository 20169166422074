import React from 'react';
import Meta from '../components/Meta';
import Services from '../components/Services';

const ServicesPage = () => {
    return (
        <div>
            <Meta title={'Servicios'} />
            <header>
                <Services />
            </header>
        </div>
    );
};

export default ServicesPage;