import AboutUs from "../components/AboutUs"
import Meta from "../components/Meta";

const ContactPage = () => {
    return (
        <body>
            <Meta title={'Nosotros'} />
            <div>
                <header>
                    <AboutUs />
                </header>
            </div>

        </body>
    );
};

export default ContactPage;