import "../components/Contact"
import Contact from '../components/Contact';
import Meta from "../components/Meta";

const ContactPage = () => {
    return (
        <body>
            <Meta title={'Contacto'} />
            <div>
                <Contact />
            </div>
        </body>
    );
};

export default ContactPage;